import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Toc from "../components/toc"

const PostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const title = post.frontmatter.title
  const tags = post.frontmatter.tags
  const { previous, next } = data
  const tocData = post.tableOfContents

  let toc = ""
  if(tocData) {
    toc = <Toc data={data.markdownRemark.tableOfContents} />
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={title}
        description={post.frontmatter.description || post.excerpt}
      />
        {tags.map(tag =>
          <h1>
            {tag}
          </h1>
        )}
        <p>
        {post.frontmatter.date}
        </p>
        <h2 itemProp="headline">{title}</h2>
        <div
          style={{
            marginBottom: 50,
          }}
        >
        </div>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
      <nav className="blog-post-nav">
      </nav>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      frontmatter {
        title
        date(formatString: "YYYY年MM月DD日")
        description
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
